import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import details1 from '../assets/images/main-banner/main-banner1.jpg'
import project2 from '../assets/images/projects/project2.jpg'
import charts from '../assets/images/services/charts.jpg'
import icon1 from '../assets/images/services/service-icon1.png'
import icon2 from '../assets/images/services/service-icon2.png'
import icon3 from '../assets/images/services/service-icon3.png'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Artificial intelligence" 
                homePageText="Services" 
                homePageUrl="/services" 
                activePageText="Artificial intelligence" 
            />
            <ServiceDetailsContent />
            <RelatedProjects />
            <Footer />
        </Layout>
    );
}

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Artificial intelligence</span>
                            <h3>Artificial intelligence</h3>
                            <p>We apply Artificial Intelligence algorithm to create general models from your data and apply those models to your projects. Facial recognition, classification, decision making are only part of the applications you might find useful.  </p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Features</h3>
                                        <ul>
                                            <li>Algoritms</li>
                                            <li>Images recognition</li>
                                            <li>Understanding social phenomena</li>
                                            <li>Model elaboration</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>By Artificial Intelligence we refer to software that can perform complex tasks typically associated with human intelligence. For example, recognizing a face within an image, understanding a question in a natural language, or making decisions. These tasks require the ability to process large amounts of data, generally going through a training phase during which the system gains experience and learns from externally established knowledge. Artificial intelligence can be of great help for sustainable development. If you want to know how, let's talk about it together.  
</p>
                            <h3>Technologies</h3>
                            <ul className="technologies-features">
                                <li><span>Tensorflow</span></li>
                                <li><span>OpenAI - GPT</span></li>
                                <li><span>llmaindex</span></li>
                                <li><span>LangChain</span></li>
                                <li><span>Python</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/app-development" >
                       App development
                    </Link>
                </li>
                <li>
                    <Link to="/digital-transformation">
                        Digital transformation
                    </Link>
                </li>
                <li>
                    <Link to="/social-balance-monitoring-evaluation">
                        Social balance, monitoring and evaluation
                    </Link>
                </li>
                <li>
                    <Link to="/case-management" >
                    Case management
                    </Link>
                </li>
                <li>
                    <Link to="/data-analysis">
                        Data Analysis
                    </Link>
                </li>
                <li>
                    <Link to="/artificial-intelligence" className="active">
                        Artificial intelligence
                    </Link>
                </li>
            </ul>
        </div>
    )
}

const RelatedProjects = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Developed projects</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/c2s">
                                    Cheese2School
                                </Link>
                            </h3>
                            <p>Mobile app to collect school data attendance via image recognition</p>

                            <Link to="/project/c2s" className="read-more-btn">
                                Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Details
